// IMPORTANT: if you adapt the constants for first page and page two here please inform #chapter-ai-data
// so that they can adapt the leadforensic script
// https://github.com/sortlist/reporting-api/blob/dev/lead_forensics_sync/public_app/public_api_helper.py#L15
export const AGENCIES_ON_FIRST_PAGE = 20;
export const AGENCIES_FROM_PAGE_TWO_ON = 100;

export const getOffsetAndLimitByPage = (page: number, limit?: number): { offset: number; limit: number } => {
  if (page <= 1) return { offset: 0, limit: limit ?? AGENCIES_ON_FIRST_PAGE };

  return {
    offset: AGENCIES_FROM_PAGE_TWO_ON * (page - 2) + AGENCIES_ON_FIRST_PAGE,
    limit: AGENCIES_FROM_PAGE_TWO_ON,
  };
};
