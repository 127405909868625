import { cx } from '@emotion/css';
import { CloseRounded } from '@mui/icons-material';
import { type SnackbarProps as MUISnackbarProps } from '@mui/material';
import { useEffect, useState, type FC, type ReactNode } from 'react';
import { createPortal } from 'react-dom';

import { COLORS } from '../../variables/colors';
import { OUTLET_ID } from './SnackbarProvider';
import * as S from './styles';

type SnackbarType = 'dark' | 'error' | 'success' | 'warning' | 'info';

export type SnackbarProps = {
  icon?: ReactNode;
  closeButton?: boolean;
  handleClose?: () => void;
  type?: SnackbarType;
  // If provided, the snackbar will render at the root of the web page
  renderInPortal?: boolean;
} & Partial<MUISnackbarProps>;

type SnackbarContentProps = {
  icon?: ReactNode;
  text: string | ReactNode;
  color?: string;
};

const SnackbarContent: FC<SnackbarContentProps> = (props) => {
  const { icon, text, color } = props;

  return (
    <div className="layout-row layout-align-center-center">
      {icon ? <span className="mr-4 layout-row layout-align-center-center">{icon}</span> : null}
      {typeof text === 'string' ? <p className={cx(color)}>{text}</p> : text}
    </div>
  );
};

export const Snackbar: FC<SnackbarProps> = (props) => {
  const {
    closeButton,
    handleClose,
    action,
    icon,
    type,
    anchorOrigin,
    message,
    autoHideDuration = 5000,
    renderInPortal,
    ...restProps
  } = props;

  const [bgColor, setBgColor] = useState(COLORS.SECONDARY_900);
  const [color, setColor] = useState('text-secondary-100');

  useEffect(() => {
    switch (type) {
      case 'error':
        setBgColor(COLORS.DANGER_500);
        setColor('text-secondary-100');
        break;
      case 'info':
        setBgColor(COLORS.NEUTRAL_100);
        setColor('text-secondary-900');
        break;
      case 'warning':
        setBgColor(COLORS.WARNING_500);
        setColor('text-secondary-900');
        break;
      case 'success':
        setBgColor(COLORS.SUCCESS_500);
        setColor('text-secondary-100');
        break;
      default:
        setBgColor(COLORS.SECONDARY_900);
        setColor('text-secondary-100');
    }
  }, [type]);

  const Element = () => (
    <S.Snackbar
      data-testid={'snackbar'}
      bgcolor={bgColor}
      action={
        closeButton ? (
          <CloseRounded
            tabIndex={0}
            role={'button'}
            className={cx(color, 'p cursor-pointer outline-none')}
            onKeyDown={handleClose}
            onClick={handleClose}
          />
        ) : (
          action
        )
      }
      anchorOrigin={anchorOrigin ? anchorOrigin : { vertical: 'bottom', horizontal: 'center' }}
      message={<SnackbarContent icon={icon} text={message} color={color} />}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      {...restProps}
    />
  );

  if (renderInPortal) {
    return createPortal(<Element />, document.getElementById(OUTLET_ID) as Element);
  }

  return <Element />;
};
